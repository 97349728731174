<script>
import MenuOptionsItem from "components/layout/context-menu/context-window-options-item"
import {UPopover} from "uloc-vue"
import TaskListPage from "components/suporte/components/task/ListPage"
import criarTarefaWindow from "components/suporte/components/task/window/criarTarefa";
export default {
  name: 'SuporteTarefasFila',
  inject: ['container'],
  mixins: [],
  components: {
    TaskListPage,
    MenuOptionsItem,
    UPopover
  },
  data () {
    return {
      routeName: 'suporte.tarefas.fila',
      listOptions: {
        loading: false,
        serverPagination: {
          page: 1,
          rowsNumber: 0,
          rowsPerPage: 20
        }
      }
    }
  },
  mounted() {
    if (this.$route.name === 'suporte.tarefas.fila') {
      this.container.$refs.menuTarefas.menu = 'fila'
    }
  },
  computed: {
  },
  methods: {
    criarTarefaWindow,
    criarTarefa () {
      this.criarTarefaWindow(null, null)
    }
  },
  meta: {
    title: 'Tarefas',
    name: 'Tarefas'
  }
}
</script>

<template>
  <div v-if="$route.name === routeName">
    <ul class="snav">
      <li><a @click="$router.push({name: 'suporte.dashboard'})">Suporte</a></li>
      <li class="cursor-pointer" @click="$router.push({name: 'suporte.tarefas'})">Tarefas</li>
      <li class="disable cursor-pointer">Filas</li>
      <li class="disable cursor-pointer">{{ container.menuActive.label }}</li>
    </ul>
    <div class="stitle">
      <h2 class="cursor-pointer">{{ container.menuActive.label }}</h2>
      <div class="sopts">
        <u-btn @click="criarTarefa" label="Criar" no-caps color="blue-8"/>
        <u-btn flat size="sm" class="m-l-xs">
          <i class="fa fa-ellipsis-h font-14" />
          <u-popover ref="popover" class="window-context-menu menu-profile min" anchor="bottom left" self="top left" :offset="[0, 15]" style="min-width: 172px">
            <div>
              <ul>
                <menu-options-item label="Configurações" close  />
              </ul>
            </div>
          </u-popover>
        </u-btn>
      </div>
    </div>
    <task-list-page v-if="!container.loading" :fila="container.menuActive.name" />
  </div>
  <router-view v-else />
</template>
